import Link from "next/link";
import { useRouter } from "next/router";
import { useRef } from "react";

import { useLanguage } from "~/contexts/providers/language";
import { useOutsideClick } from "~/hooks/outsideClick";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";
import { UrlItem } from "~/types/general.types";

import styles from "./index.module.scss";

type Props = {
  open?: boolean;
  handleClose?: () => void;
};

const Navbar = ({ open = false, handleClose }: Props) => {
  const router = useRouter();
  const currentMainPath = router.asPath.split("/")[1];
  const { language } = useLanguage();

  const listRef = useRef<HTMLUListElement | null>(null);
  const headerItemsTexts =
    language === "En"
      ? englishTranslations.homePage.header
      : frenchTranslations.homePage.header;
  const navbarItems: UrlItem[] = [
    { name: headerItemsTexts.home, href: "" },
    { name: headerItemsTexts.venues, href: "venues" },
    { name: headerItemsTexts.culture, href: "culture" },
    { name: headerItemsTexts.privateEvents, href: "private-events" },
    { name: headerItemsTexts.about, href: "about" },
    { name: headerItemsTexts.membership, href: "membership" },
  ];

  useOutsideClick(
    listRef,
    open && handleClose
      ? () => {
          setTimeout(() => handleClose(), 10);
        }
      : () => {}
  );

  return (
    <nav className={[styles.navbar, open ? styles.open : null].join(" ")}>
      <ul ref={listRef}>
        {navbarItems.map(({ name, href }, i) => (
          <li key={`navbar-item-${i}`}>
            <Link href={`/${href}`}>
              <a
                onClick={handleClose}
                className={
                  href == currentMainPath ? styles.activeUrl : undefined
                }
              >
                {name}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
