import { User } from "~/types/auth/auth.types";

import { sendRequest } from "../clients/axios";

export const getMyProfile = async () => {
  try {
    const res = await sendRequest<never, User>({
      method: "get",
      url: "/user/me",
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
