import { ConfirmForgetPasswordInput } from "~/types/auth/auth.types";

import { sendRequest } from "../clients/axios";

export const confirmForgetPassword = async (
  input: ConfirmForgetPasswordInput
) => {
  await sendRequest<ConfirmForgetPasswordInput, never>({
    method: "post",
    url: "/auth/confirm-forgot-password",
    data: input,
  });
  return true;
};
