import { useRouter } from "next/router";
import { useState } from "react";
import { removeAuth } from "utils/token.util";

import Modal from "~/components/ui/modal";
import { useAuth } from "~/contexts/providers/auth";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";

import SignInDialogue from "./dialogue";
import styles from "./index.module.scss";

const HeaderSingIn = () => {
  const { user, setUser } = useAuth();

  const [modalOpen, setModalOpen] = useState(false);

  const router = useRouter();
  const { language } = useLanguage();
  const headerItemTexts =
    language === "En"
      ? englishTranslations.homePage.header
      : frenchTranslations.homePage.header;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLogout = () => {
    removeAuth();
    setUser(undefined);
    router.push("/");
  };

  return (
    <>
      <div className={styles.headerSignIn}>
        <button
          className={styles.signinButton}
          onClick={user?.id ? handleLogout : handleOpenModal}
        >
          {user?.id ? headerItemTexts.logout : headerItemTexts.login}
        </button>
      </div>
      <Modal open={modalOpen} onClose={handleCloseModal} blur={true}>
        <SignInDialogue closeModal={handleCloseModal} />
      </Modal>
    </>
  );
};

export default HeaderSingIn;
