import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { updateUser } from "~/api/users/updateUser";
import Modal from "~/components/ui/modal";
import { useAuth } from "~/contexts/providers/auth";
import { useLocalStorage } from "~/hooks/localStorage";
import { UserStatus, UserType } from "~/types/auth/userEnums.types";
import { emailRegex } from "~/utils/constants/regex";

import ContextInput from "../contextInput";
import MainButton from "../mainButton";
import styles from "./index.module.scss";

type EditUserFormInput = {
  email?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
};

const EditUserForm = () => {
  const { user } = useAuth();
  const [cookiesAccepted] = useLocalStorage("cookiesAccepted", false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [error, setError] = useState("");
  const methods = useForm<EditUserFormInput>();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
    },
    onError: () => {
      setError("Nom d'utilisateur déjà pris.");
    },
  });

  const visible =
    !!user &&
    user.editable &&
    user.status === UserStatus.VALIDATED &&
    cookiesAccepted;

  const oldMember = !!user && user.type === UserType.OLD_MEMBER;

  useEffect(() => {
    const email = methods.getValues("email");
    if (oldMember && !(email && emailRegex.test(email))) {
      setSubmitDisabled(true);
    }
  }, []);

  const handleInputChange = () => {
    if (error) setError("");

    const { firstname, lastname, username, email } = methods.getValues();
    if (!oldMember && (!firstname || !lastname || !username)) {
      setSubmitDisabled(true);
      return;
    }
    if (oldMember && !(email && emailRegex.test(email))) {
      setSubmitDisabled(true);
      return;
    }
    setSubmitDisabled(false);
  };

  const handleSubmit = (data: EditUserFormInput) => {
    mutate(data);
  };

  if (!visible) return null;

  const { firstname, lastname, username, email } = user;

  return (
    <Modal open>
      <div className={styles.container}>
        {user.type !== UserType.OLD_MEMBER && (
          <p className={styles.welcomeText}>
            Grâce à votre carte, pendant un an, profitez d’une programmation
            exclusive ainsi qu’un accès à notre espace de travail et de
            rendez-vous convivial au Silencio des Prés. Découvrez l'intégralité
            de notre programmation dans l’onglet dédié. <br />
            <br />
            Bienvenue dans la communauté ! <br />
            <br />
            Silencio
          </p>
        )}
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              methods.handleSubmit(handleSubmit)();
            }}
          >
            <fieldset className={styles.formFieldset}>
              <legend>
                {user.type === UserType.OLD_MEMBER
                  ? "Merci de confirmer votre email."
                  : "Merci de confirmer votre nom, prénom et nom d'utilisateur."}
              </legend>
              {user.type === UserType.OLD_MEMBER ? (
                <ContextInput<EditUserFormInput>
                  name="email"
                  label="Email"
                  placeholder="Email"
                  defaultValue={email}
                  onChange={handleInputChange}
                  size={20}
                />
              ) : (
                <>
                  <ContextInput<EditUserFormInput>
                    name="firstname"
                    label="Prénom"
                    placeholder="Prénom"
                    defaultValue={firstname}
                    onChange={handleInputChange}
                  />
                  <ContextInput<EditUserFormInput>
                    name="lastname"
                    label="lastname"
                    placeholder="Nom"
                    defaultValue={lastname}
                    onChange={handleInputChange}
                  />
                  <ContextInput<EditUserFormInput>
                    name="username"
                    label="username"
                    placeholder="username"
                    defaultValue={username}
                    onChange={handleInputChange}
                  />
                </>
              )}
              <MainButton isLoading={isLoading} disabled={submitDisabled}>
                Sauvegarder
              </MainButton>
            </fieldset>
          </form>
        </FormProvider>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </Modal>
  );
};

export default EditUserForm;
