const AsperandIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96455 12C8.28455 12 9.43312 11.6229 10.376 11.0057L9.65598 9.99429C8.85026 10.5429 7.97598 10.8343 6.96455 10.8343C4.27312 10.8343 2.1474 8.69143 2.1474 6C2.1474 3.30857 4.27312 1.16571 7.13598 1.16571C9.8274 1.16571 11.7817 2.96571 11.7817 5.65714C11.7817 7.30286 11.1303 7.92 10.5131 7.92C9.84455 7.92 9.57026 7.38857 9.57026 6.51429V3.08571H8.47312V3.85714C8.16455 3.25714 7.39312 2.91429 6.65598 2.91429C5.01026 2.91429 3.93026 4.26857 3.93026 6C3.93026 7.73143 5.1474 9.08572 6.65598 9.08572C7.58169 9.08572 8.31883 8.60571 8.69598 7.90286C9.05598 8.64 9.60455 9.08572 10.5131 9.08572C11.8503 9.08572 12.9474 7.88571 12.9474 5.65714C12.9474 2.31429 10.4788 0 7.13598 0C3.62169 0 0.981689 2.65714 0.981689 6C0.981689 9.34286 3.62169 12 6.96455 12ZM6.79312 7.98857C5.8674 7.98857 5.30169 7.08 5.30169 6C5.30169 4.92 5.8674 4.01143 6.79312 4.01143C7.73598 4.01143 8.33598 4.92 8.33598 6C8.33598 7.08 7.71883 7.98857 6.79312 7.98857Z"
        fill="#616161"
      />
    </svg>
  );
};

export default AsperandIcon;
