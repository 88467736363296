import { useLanguage } from "~/contexts/providers/language";

import styles from "./index.module.scss";

const HeaderLanguageToggle = () => {
  const { language, setLanguage } = useLanguage();

  const handleSetLanguage = () => {
    setLanguage((prev) => {
      const language = prev === "En" ? "Fr" : "En";
      localStorage.setItem("language", language);
      return language;
    });
  };

  return (
    <div className={styles.headerLanguageToggle}>
      <div className={language === "En" ? styles.selected : undefined}>En</div>
      <div
        className={[
          styles.toggle,
          language === "Fr" ? styles.toggled : null,
        ].join(" ")}
        onClick={handleSetLanguage}
      >
        <div />
      </div>
      <div className={language === "Fr" ? styles.selected : undefined}>Fr</div>
    </div>
  );
};

export default HeaderLanguageToggle;
