import { ChangeEvent } from "react";
import { type Path, useFormContext } from "react-hook-form";

import styles from "./index.module.scss";

type Props<T> = {
  name: Path<T>;
  label: string; // for seo
  placeholder?: string;
  type?: string;
  size?: number;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ContextInput = <T,>({
  name,
  label,
  placeholder,
  size,
  type,
  defaultValue,
  onChange,
}: Props<T>) => {
  const { register } = useFormContext();
  return (
    <>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <input
        {...register(name, { onChange })}
        placeholder={placeholder}
        type={type ?? "text"}
        size={size ?? 12}
        className={styles.input}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default ContextInput;
