import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { storeAuthResult } from "utils/token.util";

import { confirmForgetPassword } from "~/api/auth/confirmForgotPassword";
import { confirmSignup } from "~/api/auth/confirmSignup";
import { forgetPassword } from "~/api/auth/forgotPassword";
import { loginUser } from "~/api/auth/loginUser";
import { AxiosApiError } from "~/api/clients/axios.type";
import ApplicationPayment from "~/components/application/sections/payment";
import ContextInput from "~/components/common/contextInput";
import MainButton from "~/components/common/mainButton";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";
import { LoginFormInputs, User } from "~/types/auth/auth.types";
import { UserStatus } from "~/types/auth/userEnums.types";
import { passwordRegex } from "~/utils/constants/regex";

import styles from "./index.module.scss";

enum FormState {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CONFIRM_ACCOUNT,
  CONFIRM_PAYMENT,
  PENDING_ADMIN_CONFIRMATION,
}

type FormStateText = Record<FormState, string>;

type PartialFormState = Partial<FormStateText>;

interface Props {
  closeModal: () => void;
}

const SignInDialogue = ({ closeModal }: Props) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState<FormState>(FormState.LOGIN);
  const [message, setMessage] = useState<string>("");

  const router = useRouter();
  const methods = useForm<LoginFormInputs>();
  const queryClient = useQueryClient();
  const { language } = useLanguage();

  const translation =
    language === "En" ? englishTranslations : frenchTranslations;

  const title: FormStateText = {
    [FormState.LOGIN]: translation.dialogue.title.login,
    [FormState.FORGOT_PASSWORD]: translation.dialogue.title.forgotPassword,
    [FormState.RESET_PASSWORD]: translation.dialogue.title.resetPassword,
    [FormState.CONFIRM_ACCOUNT]: translation.dialogue.title.confirmAccount,
    [FormState.CONFIRM_PAYMENT]: translation.dialogue.title.confimrPayment,
    [FormState.PENDING_ADMIN_CONFIRMATION]:
      translation.dialogue.title.pendingAdminConfirmation,
  };

  const passwordLabel: PartialFormState = {
    [FormState.LOGIN]: translation.dialogue.passwordLabel.login,
    [FormState.RESET_PASSWORD]:
      translation.dialogue.passwordLabel.resetPassword,
  };

  const submitText: FormStateText = {
    [FormState.LOGIN]: translation.dialogue.submitText.login,
    [FormState.FORGOT_PASSWORD]: translation.dialogue.submitText.forgotPassword,
    [FormState.RESET_PASSWORD]: translation.dialogue.submitText.resetPassword,
    [FormState.CONFIRM_ACCOUNT]: translation.dialogue.submitText.confirmAccount,
    [FormState.CONFIRM_PAYMENT]: translation.dialogue.submitText.confimrPayment,
    [FormState.PENDING_ADMIN_CONFIRMATION]: "",
  };

  const spanText: FormStateText = {
    [FormState.LOGIN]: translation.dialogue.spanText.login,
    [FormState.FORGOT_PASSWORD]: translation.dialogue.spanText.forgotPassword,
    [FormState.RESET_PASSWORD]: translation.dialogue.spanText.resetPassword,
    [FormState.CONFIRM_ACCOUNT]: "",
    [FormState.CONFIRM_PAYMENT]: "",
    [FormState.PENDING_ADMIN_CONFIRMATION]:
      translation.dialogue.spanText.pendingAdminConfirmation,
  };

  const spanChangeStateText: FormStateText = {
    [FormState.LOGIN]: translation.dialogue.spanChangeStateText.login,
    [FormState.FORGOT_PASSWORD]:
      translation.dialogue.spanChangeStateText.forgotPassword,
    [FormState.RESET_PASSWORD]:
      translation.dialogue.spanChangeStateText.resetPassword,
    [FormState.CONFIRM_ACCOUNT]:
      translation.dialogue.spanChangeStateText.confirmAccount,
    [FormState.CONFIRM_PAYMENT]:
      translation.dialogue.spanChangeStateText.confimrPayment,
    [FormState.PENDING_ADMIN_CONFIRMATION]: "",
  };

  const handleLastStep = () => {
    closeModal();
    router.push("/account");
  };

  const { mutate: loginMutation, isLoading: loginLoading } = useMutation(
    loginUser,
    {
      onSuccess: async (res) => {
        storeAuthResult(res);
        const user = await queryClient.fetchQuery<User>("user");
        if (user && user.status === UserStatus.CONFIRMED_COGNITO) {
          setFormState(FormState.CONFIRM_PAYMENT);
          return;
        }
        handleLastStep();
      },
      onError: (err: AxiosApiError) => {
        switch (err?.response?.data.message) {
          case "Incorrect username or password.":
            toast.error(translation.dialogue.incorrectCredentials, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setMessage(translation.dialogue.incorrectCredentials);
            break;
          case "User not confirmed":
            setMessage("");
            setFormState(FormState.CONFIRM_ACCOUNT);
            break;
        }
      },
    }
  );

  const { mutate: forgetPasswordMutation, isLoading: forgetPasswordLoading } =
    useMutation(forgetPassword, {
      onSuccess: () => {
        setFormState(FormState.RESET_PASSWORD);
        methods.setValue("password", "");
      },
    });

  const { mutate: resetPasswordMutation, isLoading: resetPasswordLoading } =
    useMutation(confirmForgetPassword, {
      onSuccess: () => {
        setFormState(FormState.LOGIN);
        setMessage(translation.dialogue.passwordChanged);
      },
      onError: (err: AxiosApiError) => {
        setMessage(err.response?.data?.message as string);
      },
    });

  const { mutate: confirmSignupMutation } = useMutation(confirmSignup, {
    onSuccess: () => {
      setFormState(FormState.LOGIN);
    },
    onError: (err: AxiosApiError) => {
      setMessage(
        err?.response?.data.message ?? translation.dialogue.errorOccured
      );
    },
  });

  const handleSubmitLocal = ({ username, code, password }: LoginFormInputs) => {
    switch (formState) {
      case FormState.LOGIN:
        if (password) loginMutation({ username, password });
        break;
      case FormState.FORGOT_PASSWORD:
        forgetPasswordMutation({ username });
        break;
      case FormState.RESET_PASSWORD:
        if (password && code) {
          resetPasswordMutation({ username, password, code });
        }
        break;
      case FormState.CONFIRM_ACCOUNT:
        if (password && code) {
          confirmSignupMutation({ username, code });
        }
        break;
    }
  };
  const handleUserChange = useCallback(() => {
    if (message !== "") setMessage("");
    const { username, password, confirmPassword, code } = methods.getValues();

    switch (formState) {
      case FormState.LOGIN:
        setSubmitDisabled(!username || !password);
        break;
      case FormState.FORGOT_PASSWORD:
        if (!username) setSubmitDisabled(true);
        break;
      case FormState.RESET_PASSWORD:
        if (
          !username ||
          !password?.match(passwordRegex) ||
          confirmPassword !== password ||
          !code
        )
          setSubmitDisabled(true);
        break;
      default:
        setSubmitDisabled(false);
    }
    setSubmitDisabled(false);
  }, [formState, message, methods]);

  useEffect(() => {
    handleUserChange();
    methods.setValue("confirmPassword", "");
  }, [formState, handleUserChange, methods]);

  return (
    <div className={styles.dialogue}>
      <h4>{title[formState]}</h4>

      {formState == FormState.RESET_PASSWORD && (
        <p
          className={styles.resetCodeText}
          dangerouslySetInnerHTML={{
            __html: translation.dialogue.accountExist,
          }}
        />
      )}

      {formState !== FormState.CONFIRM_PAYMENT ? (
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              methods.handleSubmit(handleSubmitLocal)(e);
            }}
          >
            <ContextInput<LoginFormInputs>
              name="username"
              label={translation.dialogue.username}
              placeholder={translation.dialogue.username}
              onChange={handleUserChange}
            />
            {[FormState.RESET_PASSWORD, FormState.CONFIRM_ACCOUNT].includes(
              formState
            ) && (
              <ContextInput<LoginFormInputs>
                name="code"
                label={translation.dialogue.code}
                placeholder={translation.dialogue.code}
                type="string"
                onChange={handleUserChange}
              />
            )}
            {![FormState.FORGOT_PASSWORD, FormState.CONFIRM_ACCOUNT].includes(
              formState
            ) && (
              <ContextInput<LoginFormInputs>
                name="password"
                label={passwordLabel[formState] ?? ""}
                placeholder={passwordLabel[formState]}
                type="password"
                onChange={handleUserChange}
              />
            )}
            {formState == FormState.RESET_PASSWORD && (
              <ContextInput<LoginFormInputs>
                name="confirmPassword"
                label={translation.dialogue.confirmPassword}
                placeholder={translation.dialogue.confirmPassword}
                type="password"
                size={17}
                onChange={handleUserChange}
              />
            )}
            <MainButton
              disabled={submitDisabled}
              isLoading={
                loginLoading || forgetPasswordLoading || resetPasswordLoading
              }
            >
              {submitText[formState]}
            </MainButton>
          </form>
        </FormProvider>
      ) : (
        <ApplicationPayment handleNext={handleLastStep} />
      )}

      {message && <p>{message}</p>}
      {spanText[formState] && (
        <p>
          {spanText[formState]}
          <span
            onClick={() => {
              setFormState((prev) =>
                prev == FormState.LOGIN
                  ? FormState.FORGOT_PASSWORD
                  : FormState.LOGIN
              );
            }}
          >
            → {spanChangeStateText[formState]}
          </span>
        </p>
      )}
    </div>
  );
};

export default SignInDialogue;
