import { EventType } from "~/types/event/event.types";

import { sendRequest } from "../clients/axios";

export const getMyEvents = async () => {
  const res = await sendRequest<never, EventType[]>({
    method: "get",
    url: "/event/me",
  });
  return res.data;
};
