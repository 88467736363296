import Stripe from "stripe";

import { SubscribeInput } from "~/types/stripe";

import { sendRequest } from "../clients/axios";

export interface SubscribeResponse {
  next_action: {
    redirect_to_url: {
      url: string;
      return_url: string;
    };
    use_stripe_sdk: {
      stripe_js: string;
    };
  };
}

export const setupIntent = async (input: SubscribeInput) => {
  const res = await sendRequest<SubscribeInput, SubscribeResponse>({
    method: "post",
    url: "/subscription/setup-intent",
    data: input,
  });
  return res.data;
};

export const updatePaymentMethod = async () => {
  const res = await sendRequest<never, Stripe.Response<Stripe.Subscription>>({
    method: "post",
    url: "/subscription/update-payment-method",
  });
  return res.data;
};
