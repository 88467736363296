import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type SetStateHandlerInput = boolean | ((prevState: boolean) => boolean);

const LoaderContext = createContext<
  [boolean, (state: SetStateHandlerInput) => void]
>([false, () => {}]);

const LoaderProvider: FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const [loading, setLoading] = useState(false);

  const handleSetLoading = (state: SetStateHandlerInput) => {
    setLoading(state);
  };

  return (
    <LoaderContext.Provider value={[loading, handleSetLoading]}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);

export default LoaderProvider;
