import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { useBreakpoint } from "~/contexts/providers/breakpoint";
import { useLoader } from "~/contexts/providers/loader";
import { useScrollDirection } from "~/hooks/scrollDirection";

import VerticalLogo from "../logo";
import HeaderApply from "./apply";
import styles from "./index.module.scss";
import HeaderLanguageToggle from "./languageToggle";
import Navbar from "./navbar";
import HeaderSingIn from "./signIn";
import HeaderSocials from "./socials";

const Header = () => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "desktop" || breakpoint == "widescreen";

  const logoContainerRef = useRef<HTMLAnchorElement | null>(null);
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    // use scroll direction hook to hide or show logo using inline styles
    if (logoContainerRef.current) {
      if (isDesktop) {
        logoContainerRef.current.style.transform =
          scrollDirection == "up"
            ? "translate(-50%, 0)"
            : "translate(-50%, -150%)";
      } else {
        logoContainerRef.current.style.removeProperty("transform");
      }
    }
  }, [logoContainerRef, scrollDirection, isDesktop]);

  const [loading] = useLoader();

  // this state is only to mount the logo inb the header after the loading logo has been animated
  const [loaded, setLoaded] = useState(loading);
  const [open, setOpen] = useState(false);

  // the router is to push on the href when logo is clicked on desktop
  const router = useRouter();

  // setTimeout is used to unmount the logo after it's been animated to the header (animation is 1s done in css)
  useEffect(() => {
    if (!loading) {
      setTimeout(() => setLoaded(true), 1000);
    }
    setLoaded(false);
  }, [loading]);

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  const handleClose = () => {
    setOpen(false);
  };

  const SocialsAndLanguageToggle = () => {
    return (
      <div className={styles.socialsAndLanguageToggle}>
        <HeaderSocials />
        <HeaderLanguageToggle />
      </div>
    );
  };

  return (
    <header
      className={[
        styles.header,
        open ? styles.open : null,
        loading ? "hidden" : null,
      ].join(" ")}
    >
      {!isDesktop && (
        <div className={styles.socialsAndSignInContainer}>
          <SocialsAndLanguageToggle />
          <HeaderSingIn />
        </div>
      )}
      {isDesktop && <Navbar />}
      {loaded && (
        <a
          className={[
            styles.logoContainer,
            loading ? styles.loading : null,
          ].join(" ")}
          onClick={(e) => {
            e.preventDefault();
            if (isDesktop) {
              router.push("/");
              return;
            }
            if (!open) {
              setOpen(true);
            }
          }}
          ref={logoContainerRef}
          // href="/"
        >
          <VerticalLogo />
        </a>
      )}
      {!isDesktop && <Navbar open={open} handleClose={handleClose} />}
      <div className={styles.signInContainer}>
        {isDesktop && <SocialsAndLanguageToggle />}
        {isDesktop && <HeaderSingIn />}
        <HeaderApply />
      </div>
    </header>
  );
};

export default Header;
