import { PropsWithChildren, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { getMyEvents } from "~/api/events/getMyEvents";
import { getMyProfile } from "~/api/users/getMyProfile";
import { defaultUser, useAuth } from "~/contexts/providers/auth";
import { useEvent } from "~/contexts/providers/events";
import { useLoader } from "~/contexts/providers/loader";

import EditUserForm from "../editUserForm";
import Logo from "../logo";
import styles from "./index.module.scss";

const Main = ({ children }: PropsWithChildren) => {
  const [loading] = useLoader();
  const { setUser } = useAuth();
  const { setMyEvents } = useEvent();
  const queryClient = useQueryClient();
  const [loaded, setLoaded] = useState(loading);

  const { status: userStatus } = useQuery("user", getMyProfile, {
    onSuccess: (res) => {
      setUser(res);
      queryClient.invalidateQueries("myEvents");
    },
    onError: () => {
      setUser(defaultUser);
    },
  });

  const { status: eventStatus } = useQuery("myEvents", getMyEvents, {
    onSuccess: (res) => {
      setMyEvents(res);
      return res;
    },
    onError: () => {
      setMyEvents([]);
    },
  });

  useEffect(() => {
    if (userStatus === "success" && eventStatus === "success") {
      setLoaded(!loading);
    } else if (userStatus === "error" || eventStatus === "error")
      setLoaded(!loading);
  }, [loading, userStatus, eventStatus]);

  return (
    <main
      id="main"
      className={[styles.main, loading ? styles.loading : null].join(" ")}
    >
      {!loaded && (
        <div className={styles.logoContainer}>
          <Logo />
        </div>
      )}
      <EditUserForm />
      <div className={styles.container}>{children}</div>
    </main>
  );
};

export default Main;
