import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { Breakpoint } from "~/types/general.types";

type BreakpointKey =
  | "smart-device"
  | "mobile"
  | "tablet"
  | "desktop"
  | "widescreen";

const BreakpointContext = createContext<BreakpointKey>("smart-device");

const BreakpointProvider: FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const [breakpoint, setBreakpoint] = useState<BreakpointKey>("smart-device");

  const calcInnerWidth = () => {
    const width = window.innerWidth;
    switch (true) {
      case width > Breakpoint.XL:
        setBreakpoint("widescreen");
        break;
      case width > Breakpoint.LG:
        setBreakpoint("desktop");
        break;
      case width > Breakpoint.MD:
        setBreakpoint("tablet");
        break;
      case width > Breakpoint.SM:
        setBreakpoint("mobile");
        break;
      default:
        setBreakpoint("smart-device");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      calcInnerWidth();
      window.addEventListener("resize", calcInnerWidth);
    }
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};

export const useBreakpoint = () => useContext(BreakpointContext);

export default BreakpointProvider;
