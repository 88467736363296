import { AuthResponse, ForgotPasswordType } from "~/types/auth/auth.types";

import { sendRequest } from "../clients/axios";

export const forgetPassword = async (input: ForgotPasswordType) => {
  const { username } = input;
  const res = await sendRequest<ForgotPasswordType, AuthResponse>({
    method: "post",
    url: "/auth/forgot-password",
    data: { username },
  });
  return res.data;
};
