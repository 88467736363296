import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { User } from "~/types/auth/auth.types";
import {
  CountryIso,
  NationalityIso,
  UserStatus,
  UserSubscriptionStatus,
  UserType,
} from "~/types/auth/userEnums.types";
import { SubscriptionFrequencyType } from "~/types/subscription/subscription.types";

type ContextData = {
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
};

export const defaultUser: User = {
  id: 0,
  email: "",
  firstname: "",
  lastname: "",
  createdAt: "",
  status: UserStatus.CREATED,
  editable: false,
  type: UserType.NORMAL,
  subscriptionStatus: UserSubscriptionStatus.NONE,
  subscriptionType: "MEMBER",
  subscriptionFrequency: SubscriptionFrequencyType.MONTHLY,
  discountValidationPeriod: 0,
  username: "",
  phoneNumber: "",
  profilePicturePath: "",
  newsletters: false,
  nationality: NationalityIso.FR,
  placeOfResidency: CountryIso.FR,
  stripeCardStatus: "NONE",
  bookings: [],
};

const AuthContext = createContext<ContextData>({
  setUser: () => {},
});

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User>();

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
