import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { LanguageType } from "~/types/language/language.types";

export type LanguageContextType = {
  language: LanguageType;
  setLanguage: Dispatch<SetStateAction<LanguageType>>;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "Fr",
  setLanguage: () => {},
});

const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [language, setLanguage] = useState<LanguageType>("Fr");

  useEffect(() => {
    const currentLanguage =
      typeof window !== "undefined"
        ? (localStorage.getItem("language") as LanguageType) || "Fr"
        : "Fr";
    setLanguage(currentLanguage);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageProvider;
