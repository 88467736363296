export enum Breakpoint {
  XS = 320,
  SM = 480,
  MD = 768,
  LG = 1024,
  XL = 1200,
}

export type UrlItem = {
  name: string;
  href: string;
};

export type KeysOfType<T extends object, V extends T[keyof T]> = Pick<
  T,
  { [K in keyof T]: T[K] extends V ? K : never }[keyof T]
>;
