import "~/styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";

import type { AppProps } from "next/app";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

import CookiesPopup from "~/components/common/cookiesPopup";
import Header from "~/components/common/header";
import Main from "~/components/common/main";
import ContextsProvider from "~/contexts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("language", "Fr");
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ContextsProvider>
        <CookiesPopup />
        <Header />
        <Main>
          <Component {...pageProps} />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            style={{ zIndex: 2000 }}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Main>
      </ContextsProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
