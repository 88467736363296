const frenchTranslations = {
  homePage: {
    header: {
      home: "Accueil",
      venues: "Nos lieux",
      culture: "Culture",
      privateEvents: "Privatisation",
      about: "À propos",
      membership: "Membership",
      logout: "Se déconnecter",
      login: "Se connecter",
      apply: "Devenir Membre",
    },
    intro:
      "Le Silencio est un reflet audacieux de l’air du temps. Ses lieux confidentiels, dédiés aux arts et à la fête, font de lui un rendez-vous incontournable qui réunit artistes, créatifs et entrepreneurs du monde entier. Pour ses membres, c’est un espace de liberté et d’inspiration unique en son genre.",
    corps: {
      aboutSilencio: "À propos de Silencio",
      venues: "Nos lieux",
      bookNow: "Réserver",
    },
  },
  dialogue: {
    title: {
      login: "Connectez-vous à votre compte",
      forgotPassword: "Entrer votre nom d’utilisateur",
      resetPassword: "Réinitialiser votre mot de passe",
      confirmAccount: "Recevez le code de vérification",
      confimrPayment: "Confirmez votre paiement",
      pendingAdminConfirmation: "Votre compte est en attente de validation",
    },
    passwordLabel: {
      login: "Mot de passe",
      resetPassword: "Nouveau mot de passe",
    },
    submitText: {
      login: "Se connecter",
      forgotPassword: "Envoyer le code",
      resetPassword: "Confirmer",
      confirmAccount: "Confirmer",
      confimrPayment: "Confirmer",
    },
    spanText: {
      login: "Mot de passe oublié?",
      forgotPassword: "Retour à la connexion",
      resetPassword: "Retour à la connexion",
      pendingAdminConfirmation:
        "Votre paiement a été validé avec succès ! Le Silencio va traiter votre demande d'inscription. Vous pouvez fermer cette fenêtre.",
    },
    spanChangeStateText: {
      login: "Se connecter",
      forgotPassword: "Se connecter",
      resetPassword: "Se connecter",
      confirmAccount: "Confirmer",
      confimrPayment: "Confirmer",
    },
    incorrectCredentials: "Nom d'utilisateur ou mot de passe incorrect",
    passwordChanged:
      "Mot de passe changé avec succès, merci de vous connecter.",
    errorOccured: "Une erreur est survenue",
    accountExist:
      "Si votre compte existe, vous allez recevoir par email un code de vérification.<br /> Veuillez le renseigner ci-dessous.",
    username: "Nom d'utilisateur",
    code: "Code",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
  },

  venuesPage: {
    title: "Nos lieux",
    intro:
      "Des œuvres d’art pensées comme des lieux de vie. S’ils ne se ressemblent pas, c’est parce que les spécificités qui les caractérisent sont en lien direct avec l’environnement dans lequel ils s’insèrent. Sublimés par des architectes et des designers qui portent le goût du détail à cœur, ils ont une vocation commune, vous transporter.",
  },
  privateEventsPage: {
    title: "Privatisation",
    intro:
      "Vous avez un événement à fêter ? Parce que chez nous, c’est avant tout chez vous, nos espaces d’exception sont à votre disposition pour des privatisations. Que ce soit à des fins professionnelles, ou personnelles, nous pouvons vous accompagner dans l’organisation d’un dîner, d’une soirée, d’une projection de films ou d’un séminaire. Merci de nous joindre via le formulaire de contact pour toute demande.",
    fillOurContactForm: {
      buttonText: "Formulaire de Contact",
    },
    form: {
      venue: "Le lieu",
      dropdownText: "Sélectionner une option ",
      dropDownRequiredText: "Veuillez sélectionner une option",
      numberofParticipants: "Nombre de participants",
      discription: "Description",
      discriptionText: "Décrivez votre projet",
      descriptionRequiredText: "Veuillez décrire votre projet",
      information: "Informations",
      firstName: "Prénom",
      firstNameRequiredText: "Veuillez saisir votre prénom",
      lastName: "Nom",
      lastNameRequiredText: "Veuillez saisir votre nom",
      email: "Adresse Mail",
      emailRequiredText: "Veuillez saisir votre adresse mail",
      phone: "Téléphone",
      phoneRequiredText: "Veuillez saisir votre numéro de téléphone",
      memberText: "Je suis un membre de Silencio",
      confirmation:
        "En vous inscrivant, vous acceptez la → politique de confidentialité et les → conditions générales du Silencio. Vous pouvez modifier vos méthodes de contact ou vous désabonner à tout moment.",
      emailUsText:
        "Si vous avez un projet spécifique en tête, vous pouvez également nous envoyer un mail.",
      emailUsButton: "Nous écrire",
      cancelButtonText: "Annuler",
      submitButtonText: "Envoyer",
      successMessageText: "Votre demande a bien été envoyée",
      successMessageButtonText: "Retour aux événements privés",
    },
  },
  aboutPage: {
    title: "À propos",
    intro:
      "Paris, de jour, comme de nuit, Ibiza, sous le soleil d’été. Mais aussi des rendez-vous à Cannes, Miami, ou Venise. Club de membre unique en son genre, le Silencio prend vie dans des lieux à son image, précis et résolument ancrés dans l’époque. Des lieux d’expression pour les artistes et des lieux de rencontres pour ceux qui les portent. Des lieux d’idées comme des lieux de  fêtes, des lieux de mots, de notes, de couleurs, de poésie et de mouvements. Des lieux où les cœurs et  les corps s’animent, irrigués par un souffle commun. Celui de la découverte. Cosmopolite, cet esprit est connecté à l'effervescence des capitales et à la mélodie des jardins intimes. Avant-gardiste, il explore sans relâche l’air du temps pour en saisir les parfums. Palpitant au rythme d’une programmation inspirante, il prend les contours de concerts, de projections, d’expositions, de talks, de rencontres, donne naissance à des hybrides et se fait laboratoire. À l'abri des regards, il investit des espaces de liberté où la magie peut éclore. Bienvenue au Silencio.",
    footer: {
      copyRights: "©2020 Silencio Tous droits réservés.",
      subscribe: "S’inscrire à notre newsletter",
      inputText: "Entrez votre emaill",
      apply: "Envoyez",
    },
  },
  culturePage: {
    intro:
      "En dialogue constant avec son époque, le Silencio accompagne les grands créateurs de notre siècle, les manifestations culturelles majeures et les esprits indépendants. Un engagement renouvelé depuis 10 ans qui prend des formes variées selon les lieux et l’imagination de chacun.",
  },
  membershipPage: {
    title: "Membership",
    buttonText: "Devenir Membre",
    about:
      "Devenir membre du Silencio, c’est soutenir la création contemporaine internationale en profitant de notre programmation culturelle hybride et d’accès privilégié aux grandes manifestations partenaires. C’est rejoindre une communauté de créatifs qui façonnent l’air du temps.",
    memberPerks: [
      {
        title:
          // eslint-disable-next-line
          "Un accès exclusif et prioritaire à l'ensemble de nos établissements : Paris (Rue Montmartre et Saint-Germain-des-Prés), New York, Ibiza",
      },
      {
        title:
          // eslint-disable-next-line
          "Un accès exclusif au programme culturel (concerts, films, performances, talks, dîners, visites privées…) sur l'ensemble de nos établissements",
        // description: "Réservation jusqu’à 5 événements simultanés",
      },
      {
        title:
          "Un accès prioritaire aux soirées et aux événements sur l'ensemble de nos établissements",
        // description:
        //   // eslint-disable-next-line
        //   'Du lundi au vendredi, entre 9h et 19h, au <span style="color: #4575D3">Silencio des Prés</span>',
      },
      {
        title:
          "Un espace dédié aux membres pour le coworking et les réunions en journée à Paris (Silencio des Près)",
      },
      {
        title:
          "Un invité pour les événements et deux invités pour l’espace de coworking",
        // description:
        //   // eslint-disable-next-line
        //   'Au <span style="color: #4575D3">Silencio des Prés</span>',
      },
      {
        title:
          "Un dîner offert pour 4 personnes à l’occasion de votre anniversaire (Silencio des Prés et El Silencio) ou une table en club (Silencio ou Silencio NYC)",
        // description:
        //   // eslint-disable-next-line
        //   'Remise de 50% pour dîner le mardi au <span style="color: #4575D3">Silencio des Prés</span>, de 20% pour les dîners de chefs invités, de 10% pour les locations événementielles…',
      },
      {
        title:
          "Des offres spéciales dans nos restaurants, pour les dîners de chefs et les locations événementielles",
      },
      {
        title:
          "Un accès à nos clubs partenaires (Neue House, Matador, Arts Club Dubai, JNcQUOI Club)",
      },
    ],
    membersNotice:
      "Pour info on va supprimer le forfait résident à l'étranger - on garde uniquement un tarif réduit moins de 30 ans",
    fullPrice: "Tarif annuel : 1.200€ (ou 100€/mois)",
    reducedPrice: "Tarif moins de trente ans : 600€",
  },
  applyPage: {
    title: "Devenir Membre",
    header: "Merci de renseigner votre moyen de paiement.",
    back: "Retour à l'accueil",
    applicationForm: {
      applyForMembership: "Demande d’adhésion",
      confirm: "Confirmer",
      monthly: "Mensuel",
      yearly: "Annuel",
      text: "Vous êtes sur le point de commencer votre candidature. Cela ne prendra que quelques minutes, et nous vous demanderons une photo récente, ainsi qu'une brève description de vous-même, il est donc préférable de les avoir à portée de main. Le paiement ne sera effectué que lorsque votre adhésion sera acceptée.",
      firstName: "Prénom",
      lastName: "Nom",
      username: "Nom d'utilisateur",
      confirmRules:
        "Cochez cette case pour confirmer que vous avez lu, compris et accepté les règles du Silencio.",
      changeLaterText:
        "Vous pouvez modifier vos méthodes de contact ou vous désabonner à tout moment.",
      receiveNewsletter:
        "Je souhaite recevoir des mises à jour, des newsletters et des offres du Silencio et de ses affiliés/partenaires.",
      backButton: "Précédent",
      nextButton: "Suivant",
      startApplicationButton: "Commencer la candidature",
      login: "S’identifier",
      personalInformation: "Informations Personnelles",
      password: "Mot de passe",
      confirmPassword: "Confirmer Mot de Passe",
      dataStore:
        "Vos données seront stockées conformément aux termes de notre ",
      privacyPolicy: "Politique de confidentialité.",
      minimumCharacters: ":Au moins 8 caractères",
      lowerCase: "1 minuscule",
      upperCase: "1 majuscule",
      number: "1 nombre",
      specialCharacter: "1 caractère spécial",
      address: "Coordonnées",
      phoneNumber: "Numéro de téléphone",
      address1: "Adresse Ligne 1",
      address2: "Adresse Ligne 2",
      city: "Ville",
      postalCode: "Code Postal",
      dob: "Date de Naissance",
      gender: "Genre",
      por: "Pays de Résidence",
      job: "Occupation",
      industry: "Secteur",
      jobTitle: "Intitulé du poste",
      companyName: "Nom de l'entreprise",
      professionalEmail:
        "Email professionnel (Si différent de l'email personnel)",
      town: "Ville",
      uploadPhotoText:
        "Veuillez télécharger une photo afin que nous confirmions votre identité. Pour des raisons de sécurité, nous devons nous assurer que la personne qui postule est la personne qui rejoindra le Silencio.",
      uploadPhotoPolicy:
        "Veuillez télécharger une photo en accord avec les termes de notre ",
      uploadFileText: "Téléchargez un fichier",
      imageDescription:
        "Un format carré est recommandé. Votre visage doit être centré. Les formats d’image acceptés sont les JPG et les PNG. Votre fichier ne doit pas excéder 5MB.",
      aboutYou:
        "Parlez-nous un peu de vous en quelques phrases, qui vous êtes, <br> ce qui vous importe et pourquoi vous souhaitez rejoindre le Silencio.",
      shortBio: "Courte Biographie",
      shortBioText:
        "Qu’est ce qui vous caractérise? Partagez vos hobbies, vos intérêts, vos passions et occupations…",
      joinSilencio: "Devenir Membre",
      joinSilencioText:
        "Tell US Why You Want To Join Silencio. What Interests You in SIlencio and How You Would Contribute To The Community…",
      onlineProfiles: "Profils en ligne",
      includeProfilesText:
        "Vous pouvez ajouter vos profils en ligne pour nous en dire plus à propos de vous.",
      usernameEg: "@nomdutilisateur",
      website: "Site Internet",
      enterConfirmationCode:
        "Veuillez saisir le code de confirmation envoyé dans votre mail",
      resendCodeText: "Vous n’avez pas reçu le code?",
      resendCodeButton: "Essayez à nouveau",
      submit: "Envoyer",
      paymentNote:
        "Veuillez noter que votre paiement ne sera pas débité avant l'acceptation de votre adhésion.",
      under30Text:
        "Vous êtes éligible au tarif spécial moins de 30 ans. <br /> Pour en bénéficier, veuillez nous fournir une pièce d’identité valide.",
      foreignResidentText:
        "Vous êtes éligible au tarif spécial pour les résidents internationaux.<br /> Veuillez nous transmettre un document récent justifiant de votre résidence à l’étranger.",
      uploadDocumentText: "Télécharger",
      uploadSuccessText: "Fichier téléchargé avec succès",
      uploadErrorText:
        "Erreur de téléchargement du fichier, veuillez contacter le support à l'adresse accueil@lesilencio.com",
      imageConditions:
        "Les formats d’image acceptés sont les JPG et les PNG. Votre fichier ne doit pas excéder 5MB.",
      documentConditions:
        "Les formats de document acceptés sont JPG, PNG et PDF. Votre fichier ne doit pas dépasser 5MB.",
      installment: "Choisir le mode de paiement ",
      sponsorMessage:
        "Si un membre vous a parrainé, veuillez entrer votre code parrainage",
    },
    errors: {
      errorPaymentMethod: "Merci de renseigner votre moyen de paiement.",
      paymentDeclined:
        "Votre paiement a été refusé, merci d'essayer à nouveau.",
    },
    successful: {
      title: "Merci d'avoir soumis votre candidature",
      header: "Candidature soumise avec succès :-)",
      response:
        "Votre candidature va être examinée dans les prochains 14 jours",
      validatedTitle: "Si votre candidature est validée",
      validatedMessage1: "Vous recevrez un email avec tous les détails",
      validatedMessage2:
        "Vous recevrez votre carte membre dans les 48h après validation.",
      validatedMessage3: "Votre paiement sera automatiquement débité.",
      validatedMessage4:
        "Merci de vous être inscrit à notre newsletter. A bientôt !",
    },
  },
  accountPage: {
    member: "Membre",
    welcome: "Bienvenue",
    attending: "Inscrit",
    waitingList: "Liste d'attente",
    calendar: "Calendrier",
    all: "Tout",
    locations: "Lieux",
    cenima: "Cinéma",
    event: "Événement",
    guest: "Invité",
    invitation: "Invitation",
    book: "Réserver",
    person: "personne",
    invitationText1: "Vous pouvez inviter",
    invitationText2: "à venir avec vous à cet événement.",
    firstname: "Prénom",
    lastname: "Nom",
    confirmReservation: "Confirmer la réservation",
    cancel: "Annuler",
    inviteGuests: "Inviter des invités",
    personalInformation: "Informations Personnelles",
    login: "Identifiants",
    update: "Mettre à jour",
    mySubscription: "Mon abonnement",
    payment: "Informations de paiement",
    toChangeInformation:
      "Pour modifier d’autres informations vous concernant, veuillez contacter",
    cancelSubscription: "Annuler mon abonnement",
    reactivateMySubscription: "Réactiver mon abonnement",
    waitlist: "Liste d’attente",
    invitedGuest: "Votre Invité",
    invitedGuests: "Vos Invités",
    remove: "Supprimer",
    addGuest: "Ajouter Invité",
    close: "Fermer",
    readMore: "En savoir plus",
    from: "Du",
    to: "Au",
    sponsorship: {
      intro: "Parrainez un ami et recevez un mois de membership offert",
      friendInfo: "Coordonnées de votre ami",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Email",
      send: "Envoyer",
      sponsorCode: "Code Parrainage",
      sponsorCodeRequest:
        "Veuillez transmettre ce code de parrainage à votre ami",
      sendByEmail: "Vous pouvez aussi directement lui envoyer par mail",
      generateCode: "Générer le Code Parrainage",
      sponsorErrorMessage: "Veuillez remplir tous les champs",
      generateSponsorCodeFirst:
        "Veuillez d'abord générer un code de parrainage",
      sponsorCodeSent: "Code de parrainage envoyé",
    },
    birthday: {
      paragraph1:
        "Pour votre anniversaire, vous bénéficiez d’un dîner offert au Silencio des Prés.",
      paragraph2: "Vous pourrez profiter de notre menu spécial anniversaire.",
      notifyText:
        "N’oubliez pas d’en informer le personnel à votre arrivée au restaurant.",
    },
    loginForm: {
      legend: "Identifiants",
      username: "username",
      oldPassword: "old password",
      password: "Mot de passe",
      update: "Mise à jour du mot de passe",
      newPassword: "Nouveau Mot de passe",
      confirmNewPassword: "Confirmer mot de passe",
      passwordsNotMatch: "Les mots de passe ne correspondent pas",
      lowercase: "au moins une lettre minuscule",
      uppercase: "au moins une lettre majuscule",
      number: "au moins un chiffre",
      specialCharacter: "au moins un caractère spécial",
      passwordLength: "au moins 8 caractères",
      passwordUpdated: "Mot de passe mis à jour",
      passwordUpdateFormButton: "Modifier",
      passwordFailed: "Mot de passe non mis à jour",
    },
  },
};

export default frenchTranslations;
