const englishTranslations = {
  homePage: {
    header: {
      home: "Home",
      venues: "Venues",
      culture: "Culture",
      privateEvents: "Private Events",
      about: "About",
      membership: "Membership",
      logout: "Log out",
      login: "Log in",
      apply: "Apply",
    },
    intro:
      "Silencio is a daring reflection of our times. Its confidential locations, dedicated to arts and celebration, make it an unmissable “rendez-vous” that brings together artists, creatives, and entrepreneurs from all over the world. It is a one-of-a-kind space of freedom and inspiration for its members.",
    corps: {
      aboutSilencio: "About Silencio",
      venues: "Venues",
      bookNow: "Booking",
    },
  },
  dialogue: {
    title: {
      login: "Log in to your account",
      forgotPassword: "Enter your username",
      resetPassword: "Reset your password",
      confirmAccount: "Receive the verification code",
      confimrPayment: "Confirm your payment",
      pendingAdminConfirmation: "Your account is awaiting validation",
    },
    passwordLabel: {
      login: "Password",
      resetPassword: "New password",
    },
    submitText: {
      login: "Log in",
      forgotPassword: "Send the code",
      resetPassword: "Confirm",
      confirmAccount: "Confirm",
      confimrPayment: "Confirm",
    },
    spanText: {
      login: "Forgot your password?",
      forgotPassword: "Back to login",
      resetPassword: "Back to login",
      pendingAdminConfirmation:
        "Your payment has been successfully validated! Silencio will process your application. You can close this window.",
    },
    spanChangeStateText: {
      login: "Log in",
      forgotPassword: "Log in",
      resetPassword: "Log in",
      confirmAccount: "Confirm",
      confimrPayment: "Confirm",
    },
    incorrectCredentials: "username or password incorrect",
    passwordChanged: "Password successfully changed, please login.",
    errorOccured: "An error has occurred",
    accountExist: `If your account exists, you should receive an email containing your
    reset code <br />
    Please provide it below`,
    username: "Username",
    code: "Code",
    password: "Password",
    confirmPassword: "Confirm password",
  },
  venuesPage: {
    title: "Venues",
    intro:
      "They are works of art conceived as living spaces. As each venue is designed to achieve harmony with its surroundings, no two are the same. Through their exquisite attention to detail, the architects and designers who gave birth to these distinct spaces fulfill a shared vision: whisk you away to another world.",
  },
  privateEventsPage: {
    title: "Private Events",
    intro:
      "A special event coming up? Because we want you to feel at home with us, our spaces are available to you for private hire. Whether for business (corporate events, film screenings, dinners) or personal purposes (birthdays, weddings etc.), we are here to advise you on organizing your celebration. For inquiries, please get in touch with us via the contact form.",
    fillOurContactForm: {
      buttonText: "Fill in Our Contact Form",
    },
    form: {
      venue: "Venue",
      dropdownText: "Select an option in the dropdown",
      dropDownRequiredText: "Please select a venue",
      numberofParticipants: "Number of Participants",
      discription: "Description",
      discriptionText: "Describe your project in this box",
      descriptionRequiredText: "Please describe your project",
      information: "Information",
      firstName: "First Name",
      firstNameRequiredText: "Please enter your first name",
      lastName: "Last Name",
      lastNameRequiredText: "Please enter your last name",
      email: "Email Address",
      emailRequiredText: "Please enter your email address",
      phone: "Phone",
      phoneRequiredText: "Please enter your phone number",
      memberText: "I am a Silencio Member",
      confirmation:
        "By registering, you agree to Silencio's → Privacy Policy and → Terms and Conditions. You may change your contact methods or unsubscribe at any time.",
      emailUsText:
        "You have a specific project in mind you can also email us instead.",
      emailUsButton: "Email Us",
      cancelButtonText: "Cancel",
      submitButtonText: "Send",
      successMessageText: "Your message has been sent successfully",
      successMessageButtonText: "Back to private events",
    },
  },
  aboutPage: {
    title: "About",
    intro:
      "A playground for artists and a meeting spot for those who support them, an uncommon think-tank and an attractive party scene, a realm of words, music, colors, poetry and movement. Silencio is a one-of-a-kind members’ club. Here, hearts and bodies come alive, nourished by the shared breath of discovery. Whether in Paris, Ibiza, or even Cannes, Miami or Venice, whether in broad daylight or in the glowing darkness of velvety nights, with its cutting-edge curating, Silencio relentlessly explores the now to capture the essence of tomorrow. Its cosmopolitan mindset comes to life in places infused with both the vibrancy of capital cities and the melodies of inner worlds. Beating to the rhythm of an inspiring program, it takes shape through concerts, screenings, exhibitions, talks, and encounters, giving birth to hybrid creations. In our intimate settings, creatives are given the utmost freedom for magic to happen. Welcome to Silencio.",
    footer: {
      copyRights: "©2020 Silencio All rights reserved.",
      subscribe: "Subscribe to our newsletter",
      inputText: "Type in your email",
      apply: "Apply",
    },
  },
  culturePage: {
    intro:
      "In constant dialogue with its time, Silencio collaborates with the greatest creative figures of our century, the most important cultural events, and indie artists from all over the world. For the past decade Silencio has renewed its commitment in various ways, according to the places, projects and artists involved.",
  },
  membershipPage: {
    title: "Membership",
    buttonText: "Applying for Membership",
    about:
      "Being a member of Silencio means actively supporting international creation while enjoying a hybrid cultural program and benefiting from special access to major cultural events. Joining Silencio's membership program is being part of a creative community designing our times.",
    memberPerks: [
      {
        title:
          // eslint-disable-next-line
          `Exclusive, priority access to all our establishments: Paris (Rue Montmartre and Saint-Germain-des-Prés), New York, Ibiza`,
        description:
          "concerts, films, performances, talks, dinners, private visits and more",
      },
      {
        title:
          "Exclusive access to the cultural programme (concerts, films, performances, talks, dinners, private visits, etc.) in all our establishments",
        // description:
        //   // eslint-disable-next-line
        //   'Monday to Friday, between 9am and 7pm, at <span style="color: #4575D3">Silencio des Prés</span>',
      },
      {
        title:
          "Priority access to evenings and events in all our establishments",
        // description:
        //   // eslint-disable-next-line
        //   'Monday to Friday, between 9am and 7pm, at <span style="color: #4575D3">Silencio des Prés</span>',
      },
      {
        title:
          "A space dedicated to members for coworking and daytime meetings in Paris (Silencio des Près)",
      },
      {
        title: "One guest for events and two guests for the coworking space",
        // description:
        //   // eslint-disable-next-line
        //   'A dinner for 4 at <span style="color: #4575D3">Silencio des Prés</span>',
      },
      {
        title:
          "A dinner for 4 on the occasion of your birthday (Silencio des Prés and El Silencio) or a table in a club (Silencio or Silencio NYC)",
        // description:
        //   // eslint-disable-next-line
        //   '50% discount for dinner on Tuesday at <span style="color: #4575D3">Silencio des Prés</span>, 20% discount for dinners with invited chefs, 10% discount for private events...',
      },
      {
        title:
          "Special offers in our restaurants, for chefs' dinners and event hire",
      },
      {
        title:
          "Access to our partner clubs (Neue House, Matador, Arts Club Dubai, JNcQUOI Club)",
      },
    ],
    membersNotice:
      "For your information, we will be cancelling the resident abroad pass - we will only be keeping a reduced price for those under 30.",
    fullPrice: "Annual fee: 1.200€ (or 100€/month)",
    reducedPrice: "Under 30 years old : 600€.",
  },
  applyPage: {
    title: "Apply",
    header: "Please enter your payment method.",
    back: "Back to home page",
    applicationForm: {
      applyForMembership: "Apply for Membership",
      confirm: "Confirm",
      monthly: "Monthly",
      yearly: "Yearly",
      text: "You're about to apply for Membership for Silencio. It will take few minutes to apply, and we'll ask you for a recent photo, plus a short description about yourself, so it's a good idea to have these ready. You can save and come back to your application at any time. Payment will only be taken when your membership is accepted.",
      firstName: "First Name",
      lastName: "Last Name",
      username: "Username",
      confirmRules:
        "Check this box to confirm that you have read, understood and accepted the rules of Silencio.",
      changeLaterText:
        "You may change your contact methods or unsubscribe at any time",
      receiveNewsletter:
        "I would like to receive updates, news and offers from Silencio and its affiliates.",
      backButton: "Back",
      nextButton: "Next",
      startApplicationButton: "Start Membership Application",
      login: "Login",
      personalInformation: "Personal Information",
      password: "Password",
      confirmPassword: "Confirm Password",
      dataStore:
        "Your data will be stored in accordance with the terms in our ",
      privacyPolicy: "privacy policy.",
      minimumCharacters: "At least 8 characters",
      lowerCase: "1 lowercase letter",
      upperCase: "1 uppercase letter",
      number: "1 number",
      specialCharacter: "1 special character",
      address: "Address",
      phoneNumber: "Phone Number",
      address1: "Address Line One",
      address2: "Address Line Two",
      city: "City",
      postalCode: "Postal Code/Zip",
      dob: "Date of Birth",
      gender: "Gender",
      por: "Place of Residence",
      job: "Job",
      industry: "Industry",
      jobTitle: "Job Title",
      companyName: "Company Name",
      professionalEmail: "Professional Email (If different from personal)",
      town: "Town",
      uploadPhotoText:
        "Please upload a photo so that we can confirm your identity.",
      uploadPhotoPolicy:
        "Please download/ upload a recent picture in accordance with the term is our",
      uploadFileText: "Upload a file",
      imageDescription:
        "A square format is recommended. Be sure to appear in the center of the image. Accepted image formats are JPG and PNG and the file must not exceed 5MB.",
      aboutYou:
        "Tell us a little about yourself in a few sentences, tell us who you are, <br> what you care about and why you want to join Silencio.",
      shortBio: "Short Biography",
      shortBioText:
        "Tell Us What Makes You the Person You Are. Describe Your Hobbies, Interest, Passions And Occupation…",
      joinSilencio: "Join Silencio",
      joinSilencioText:
        "Tell US Why You Want To Join Silencio. What Interests You in SIlencio and How You Would Contribute To The Community…",
      onlineProfiles: "Online Profiles",
      includeProfilesText:
        "You can include online profiles to tell us more about you.",
      usernameEg: "@username",
      website: "Website",
      enterConfirmationCode:
        "Please enter the confirmation code sent to your email",
      resendCodeText: "Didn’t receive a code?",
      resendCodeButton: "Try Again",
      submit: "Submit",
      paymentNote:
        "Please note that your payment will not be charged until your membership is accepted.",
      under30Text:
        "You qualify for Under 30 membership, which means you get a reduced annual rate. <br /> You&apos;ll need to provide a valid photo ID.",
      foreignResidentText:
        "You qualify for International membership, which means you get a reduced annual rate. <br /> You&apos;ll need to provide a valid proof of foreign residency.",
      uploadDocumentText: "Upload",
      uploadSuccessText: "File uploaded successfully",
      uploadErrorText:
        "Error uploading file, please contact support at accueil@lesilencio.com",
      imageConditions:
        "Accepted image formats are JPG and PNG. Your file must not exceed 5MB.",
      documentConditions:
        "Accepted document formats are JPG, PNG and PDF. Your file must not exceed 5MB.",
      installment: "Select Installment",
      sponsorMessage:
        "If a Silencio member is sponsoring you, please enter your sponsor code",
    },
    errors: {
      errorPaymentMethod: "Please enter your payment method.",
      paymentDeclined: "Your payment was declined, please try again.",
    },
    successful: {
      title: "Thank you for submitting your application",
      header: "Application successfully submitted :-)",
      response: "Your application will be reviewed within the next 14 days.",
      validatedTitle: "If your application is validated",
      validatedMessage1: "You will receive an email with all the details",
      validatedMessage2:
        "You will receive your membership card within 48 hours after validation.",
      validatedMessage3: "Your payment will be automatically debited.",
      validatedMessage4:
        "Thank you for subscribing to our newsletter. See you soon!",
    },
  },
  accountPage: {
    member: "Member",
    welcome: "Welcome",
    attending: "Attending",
    waitingList: "Waiting List",
    calendar: "Calendar",
    all: "All",
    locations: "Locations",
    cenima: "Cinema",
    event: "Event",
    guest: "Guest",
    invitation: "Invitation",
    invitedGuest: "Your Guest",
    invitedGuests: "Your Guests",
    book: "Book",
    person: "person",
    invitationText1: "You have the possibility to bring",
    invitationText2: "with you at this event.",
    firstname: "First Name",
    lastname: "Last Name",
    confirmReservation: "Confirm Reservation",
    cancel: "Cancel",
    inviteGuests: "Invite Guests",
    personalInformation: "Personal Information",
    login: "Login",
    update: "Update",
    mySubscription: "My Subscription",
    payment: "Payment",
    toChangeInformation:
      "To change other information about yourself, please contact",
    cancelSubscription: "Cancel Subscription",
    reactivateMySubscription: "Reactivate My Subscription",
    waitlist: "Waitlist",
    remove: "Remove",
    addGuest: "Add Guest",
    close: "Close",
    readMore: "Read More",
    from: "From",
    to: "To",
    sponsorship: {
      intro:
        "You can request a sponsorship for a friend and get a free month of membership",
      friendInfo: "Your friend’s Info",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      send: "Send",
      sponsorCode: "Sponsor Code",
      sponsorCodeRequest: "Please send your friend this sponsor code",
      sendByEmail: "You can also directly send it by email",
      generateCode: "Generate sponsorship code",
      sponsorErrorMessage: "Please fill all the fields",
      generateSponsorCodeFirst: "Please generate a sponsor code first",
      sponsorCodeSent: "Sponsor code sent",
    },
    birthday: {
      paragraph1:
        "On the occasion of your birthday, you can enjoy a free dinner at the Silencio des Prés.",
      paragraph2: "You can enjoy the special birthday menu.",
      notifyText:
        "Be sure to notify the staff when you arrive at the restaurant.",
    },
    loginForm: {
      legend: "Login",
      username: "username",
      password: "password",
      oldPassword: "old password",
      update: "Update password",
      newPassword: "new password",
      confirmNewPassword: "Confirm password",
      passwordsNotMatch: "Passwords do not match",
      lowercase: "at least one lowercase letter",
      uppercase: "at least one uppercase letter",
      number: "at least one number",
      specialCharacter: "at least one special character",
      passwordLength: "at least 8 characters",
      passwordUpdated: "Password updated",
      passwordUpdateFormButton: "Modify",
      passwordFailed: "Password update failed",
    },
  },
};

export default englishTranslations;
