import { User } from "~/types/auth/auth.types";
import { UpdateUserInput } from "~/types/user/user.types";

import { sendRequest } from "../clients/axios";

export const updateUser = async (input: UpdateUserInput) => {
  const res = await sendRequest<UpdateUserInput, User>({
    method: "patch",
    url: "/user/update",
    data: input,
  });
  return res.data;
};
