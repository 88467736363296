import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { EventType } from "~/types/event/event.types";

type ContextData = {
  myEvents: EventType[];
  setMyEvents: Dispatch<SetStateAction<EventType[]>>;
};
const EventContext = createContext<ContextData>({
  myEvents: [],
  setMyEvents: () => {},
});

const EventProvider = ({ children }: PropsWithChildren) => {
  const [myEvents, setMyEvents] = useState<EventType[]>([]);

  return (
    <EventContext.Provider value={{ myEvents, setMyEvents }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => useContext(EventContext);

export default EventProvider;
