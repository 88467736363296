import { AuthResponse, SignInInputs } from "~/types/auth/auth.types";

import { sendRequest } from "../clients/axios";

export const loginUser = async (input: SignInInputs) => {
  const res = await sendRequest<SignInInputs, AuthResponse>({
    method: "post",
    url: "/auth/login",
    data: input,
  });
  return res.data;
};
