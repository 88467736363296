import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { getStripeCardStatus } from "~/api/stripe/getStatus";
import { setupIntent } from "~/api/stripe/subscribe";
import { updateUser } from "~/api/users/updateUser";
import MainButton from "~/components/common/mainButton";
import { useAuth } from "~/contexts/providers/auth";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";
import { UserType } from "~/types/auth/userEnums.types";
import { StripeCardStatus } from "~/types/stripe";
import { SubscriptionFrequencyType } from "~/types/subscription/subscription.types";
import { SubscriptionFrequency } from "~/types/user/user.types";
import { openUrl } from "~/utils/functions/dom.functions";
import { getPaymentText } from "~/utils/functions/general.functions";

import styles from "./index.module.scss";

type SubscriptionFrequencySpans = {
  name: string;
  value: SubscriptionFrequency;
};

type Props = {
  handleNext: () => void;
};

const CheckoutForm = ({ handleNext }: Props) => {
  const [cardStatus, setCardStatus] = useState<StripeCardStatus>("NONE");
  const [error, setError] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);
  const { language } = useLanguage();
  const applyTexts =
    language === "En"
      ? englishTranslations.applyPage
      : frenchTranslations.applyPage;

  const subscriptionFrequencies: SubscriptionFrequencySpans[] = [
    {
      name: applyTexts.applicationForm.monthly,
      value: SubscriptionFrequencyType.MONTHLY,
    },
    {
      name: applyTexts.applicationForm.yearly,
      value: SubscriptionFrequencyType.YEARLY,
    },
  ];

  const queryClient = useQueryClient();
  const { user } = useAuth();

  const { mutate, isLoading } = useMutation(setupIntent, {
    onError: () => {
      setPaymentSubmitted(false);
    },
    onSuccess: (resp) => {
      const url = resp?.next_action?.redirect_to_url?.url;
      if (url) openUrl(url);
      const sdk = resp?.next_action?.use_stripe_sdk?.stripe_js;
      if (sdk) openUrl(sdk);
    },
  });

  const { mutate: changeSubscription } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
    onError: () => {
      setError(applyTexts.errors.errorPaymentMethod);
    },
  });

  const StripeElementOptions = useMemo(
    () => ({
      style: {
        base: {
          cursor: "pointer",
          color: "#fff",
          "::placeholder": {
            color: "grey",
          },
        },
        invalid: {
          color: "#c23d4b",
        },
      },
    }),
    []
  );

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (elements && stripe) {
      const cardNumberElement = elements.getElement("cardNumber");

      if (cardNumberElement) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
        });
        if (paymentMethod && paymentMethod?.card) {
          setPaymentSubmitted(true);
          mutate({
            paymentMethod: paymentMethod.id,
            stripeLast4Digit: paymentMethod.card.last4,
          });
        }
      }
    }
  };

  const handleChangeSubscription = (
    subscriptionFrequency: SubscriptionFrequency
  ) => {
    changeSubscription({ subscriptionFrequency });
  };

  useEffect(() => {
    if (cardStatus === "ACCEPTED") {
      if (!accepted) {
        setTimeout(() => {
          setAccepted(true);
          queryClient.invalidateQueries(["user"]);
          handleNext();
        }, 5000);
      }
    }
    if (cardStatus === "REFUSED") setError(applyTexts.errors.paymentDeclined);
  }, [cardStatus, accepted, queryClient, handleNext]);

  useEffect(() => {
    const interval = setInterval(() => {
      getStripeCardStatus().then((res) => {
        setCardStatus(res.stripeCardStatus);
      });
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <p>
        {!!user && user.type !== UserType.OLD_MEMBER && (
          <p>{applyTexts.applicationForm.paymentNote}</p>
        )}
      </p>
      <p>{!!user && <p>{getPaymentText(user, language)}</p>}</p>
      {!!user && (
        <div className={styles.changeSubscriptionContainer}>
          {subscriptionFrequencies.map(({ name, value }) => (
            <span
              key={value}
              onClick={() => {
                handleChangeSubscription(value);
              }}
              className={
                user.subscriptionFrequency == value ? styles.selected : ""
              }
            >
              {name}
            </span>
          ))}
        </div>
      )}

      <div className={styles.centerContent}>
        <CardNumberElement
          className={styles.CardNumberElement}
          options={StripeElementOptions}
        />

        <CardExpiryElement
          className={styles.CardNumberElement}
          options={StripeElementOptions}
        />

        <CardCvcElement
          className={styles.CardNumberElement}
          options={StripeElementOptions}
        />
        <MainButton
          isLoading={
            isLoading || (cardStatus === "CREATED" && paymentSubmitted)
          }
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {applyTexts.applicationForm.confirm}
        </MainButton>
        {error && <p className={styles.error}>{error}</p>}
      </div>
      {/* 
      <div>
        <p>Sponsor</p>
        <p>
          If an existing member is Sponsoring you,
          <br /> please enter the code he gave you
        </p>
      </div> */}
    </>
  );
};

const ApplicationPayment = ({ handleNext }: Props) => {
  const key = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API;

  const stripePromise = loadStripe(key ?? "");

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm handleNext={handleNext} />
    </Elements>
  );
};

export default ApplicationPayment;
