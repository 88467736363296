import AsperandIcon from "~/components/icons/asperand.icon";
import InstagramIcon from "~/components/icons/instagram.icon";

import styles from "./index.module.scss";

const HeaderSocials = () => {
  return (
    <div className={styles.headerSocials}>
      <a href="mailTo:contact@lesilencio.com">
        <AsperandIcon />
      </a>
      <a
        href="https://www.instagram.com/silencioclub/?hl=fr"
        target={"_blank"}
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>
    </div>
  );
};

export default HeaderSocials;
