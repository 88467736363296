import axios from "axios";
import { getCookie, hasCookie, removeCookies, setCookies } from "cookies-next";

import { AuthResponse } from "~/types/auth/auth.types";

export function setToken() {
  const accessToken = getCookie("accessToken") as string;
  if (hasCookie("accessToken")) {
    axios.defaults.headers.common.Authorization = "Bearer " + accessToken;
  } else {
    axios.defaults.headers.common.Authorization = false;
  }
}

export function storeAuthResult(response: AuthResponse) {
  setCookies("accessToken", response.AuthenticationResult.AccessToken);

  if (response.AuthenticationResult.RefreshToken)
    setCookies("refreshToken", response.AuthenticationResult.RefreshToken);
  setToken();
}

export function storeSocialAuthResult(token: string) {
  setCookies("accessToken", token);
  setToken();
}

export function removeAuth() {
  removeCookies("accessToken");
  removeCookies("refreshToken");
  setToken();
}
