import Link from "next/link";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEventHandler,
  PropsWithChildren,
} from "react";

import LoadingIcon from "~/components/icons/loading.icon";

import styles from "./index.module.scss";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  theme?: "white" | "grey" | "black" | "transparent-grey" | "yellow" | "green";
  uiOnly?: boolean;
  href?: string;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const MainButton = ({
  theme = "black",
  uiOnly = false,
  children,
  className,
  href,
  isLoading,
  disabled,
  onClick,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={[
        styles.mainButton,
        styles[theme],
        className,
        uiOnly ? styles.uiOnly : null,
      ].join(" ")}
      disabled={disabled || isLoading}
      {...props}
      onClick={onClick}
    >
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>{!!href ? <Link href={href}>{children}</Link> : children}</>
      )}
    </button>
  );
};

export default MainButton;
