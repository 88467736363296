import MainButton from "~/components/common/mainButton";
import Modal from "~/components/ui/modal";
import { useLocalStorage } from "~/hooks/localStorage";

import styles from "./index.module.scss";

const CookiesPopup = () => {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "cookiesAccepted",
    false
  );
  const handleClose = () => {
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) return null;

  return (
    <Modal open={!cookiesAccepted} onClose={handleClose}>
      <div className={styles.container}>
        <span>Nous utilisons des cookies</span>
        <MainButton onClick={handleClose}>Accepter &#38; Continuer</MainButton>
      </div>
    </Modal>
  );
};

export default CookiesPopup;
