export function scrollWithoutHistory(e: Event, element: string) {
  e.preventDefault();
  const el = document.querySelector(element);
  if (el) {
    el.scrollIntoView();
  }
}

export function openUrl(url: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.click();
  document.body.removeChild(a);
}
