import { PropsWithChildren } from "react";

import AuthProvider from "./providers/auth";
import BreakpointProvider from "./providers/breakpoint";
import EventProvider from "./providers/events";
import LanguageProvider from "./providers/language";
import LoaderProvider from "./providers/loader";

const ContextsProvider = ({ children }: PropsWithChildren) => {
  return (
    <BreakpointProvider>
      <AuthProvider>
        <EventProvider>
          <LoaderProvider>
            <LanguageProvider>{children}</LanguageProvider>
          </LoaderProvider>
        </EventProvider>
      </AuthProvider>
    </BreakpointProvider>
  );
};

export default ContextsProvider;
