import { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom";

// import Backdrop from "../Backdrop";
// import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

type ShowAnimations =
  | "none"
  | "slide-up"
  | "slide-down"
  | "slide-left"
  | "slide-right"
  | "fade-in"
  | "expand";

type Props = {
  open?: boolean;
  closeOnOutsideClick?: boolean;
  showAnimation?: ShowAnimations;
  hideAnimation?: "none" | "slide-down";
  closeButton?: JSX.Element | null;
  onClose?: () => void;
  blur?: boolean;
};

const Modal = ({
  open = false,
  closeOnOutsideClick = true,
  showAnimation = "none",
  hideAnimation = "none",
  closeButton = null,
  onClose,
  children,
  blur = false,
}: PropsWithChildren<Props>) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setElement(document.getElementById("__next"));
  }, []);

  if (!open || !element) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={[styles.modalOverlay, blur ? styles.blur : null].join(" ")}
        onClick={() => {
          if (closeOnOutsideClick && onClose) {
            onClose();
          }
        }}
      />
      <div
        className={[
          styles.modal,
          styles[showAnimation],
          styles[hideAnimation],
        ].join(" ")}
      >
        {closeButton}
        {children}
      </div>
    </>,
    element
  );
};

export default Modal;
