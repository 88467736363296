import { StripeGetCardStatusResponse } from "~/types/stripe";

import { sendRequest } from "../clients/axios";

export const getStripeCardStatus = async () => {
  const res = await sendRequest<never, StripeGetCardStatusResponse>({
    method: "get",
    url: "/user/check-payment",
  });
  return res.data;
};
