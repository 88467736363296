export type SubscriptionType =
  | "MEMBER"
  | "REDUCED_MEMBER"
  | "SPECIAL"
  | "REDUCED_SPECIAL"
  | "MEMBER_PLUS";

export enum SubscriptionStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
}

export enum SubscriptionFrequencyType {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
}

export type Subscription = {
  id: number;
  createdAt: Date;
  updatedAt?: Date;
  renewalDate?: Date;
  validityDate?: Date;
  cancelDate?: Date;
  failedDate?: Date;
  scheduleId?: string;
  confirmedPayment: boolean;
  price: number;
  subscriptionStatus: SubscriptionStatus;
  subscriptionType: SubscriptionType;
  subscriptionFrequency: SubscriptionFrequencyType;
  renew: boolean;
  userId: number;
};
