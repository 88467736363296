import Link from "next/link";

import { useAuth } from "~/contexts/providers/auth";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";

import MainButton from "../../mainButton";

const HeaderApply = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const headerItemsTexts =
    language === "En"
      ? englishTranslations.homePage.header
      : frenchTranslations.homePage.header;
  return (
    <MainButton theme="white">
      <Link href={user?.id ? "/account" : "/apply"}>
        {user?.id ? `${user.firstname}` : headerItemsTexts.apply}
      </Link>
    </MainButton>
  );
};

export default HeaderApply;
