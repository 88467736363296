import {
  ConfirmSignupInput,
  ConfirmSignupResponse,
} from "~/types/auth/auth.types";

import { sendRequest } from "../clients/axios";

export const confirmSignup = async (input: ConfirmSignupInput) => {
  const res = await sendRequest<ConfirmSignupInput, ConfirmSignupResponse>({
    method: "post",
    url: "/auth/confirm-signup",
    data: input,
  });
  return res.data;
};
