import { User } from "~/types/auth/auth.types";
import { LanguageType } from "~/types/language/language.types";
import { SubscriptionFrequencyType } from "~/types/subscription/subscription.types";

export const getObjectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as Array<keyof T>;

export const getObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Array<[keyof T, T[keyof T]]>;

export function objectToSelectionSet<T extends object>(obj: T) {
  return getObjectEntries(obj)
    .map((entry) => ({
      value: new String(entry[0]).toString(),
      text: new String(entry[1]).toString(),
    }))
    .sort((a, b) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
}

export function isEmptyObject(obj: object) {
  for (const _key in obj) {
    return false;
  }
  return true;
}

/**
 * @description Get the payment text based on the user type.
 * @param user
 * @returns payment text
 */
export function getPaymentText(user: User, language: LanguageType) {
  const special = user.subscriptionType === "SPECIAL";
  const reducedSpecial = user.subscriptionType === "REDUCED_SPECIAL";
  const reduced = user.subscriptionType === "REDUCED_MEMBER";
  const monthly =
    user.subscriptionFrequency == SubscriptionFrequencyType.MONTHLY;

  const monthlyPrice = reducedSpecial
    ? 37.5
    : special
    ? 75
    : reduced
    ? 50
    : 100;
  const annualPrice = reducedSpecial
    ? 450
    : special
    ? 900
    : reduced
    ? 600
    : 1200;

  return language === "Fr"
    ? `Tarif ${
        monthly ? `mensuel de ${monthlyPrice}€` : `annuel de ${annualPrice}€`
      }`
    : `${
        monthly
          ? `Monthly fee of ${monthlyPrice}€`
          : `Annual fee of ${annualPrice}€`
      }`;
}
